import request from '@/utils/request'

//获取档次列表
export function getGradeList(params) {
    return request({
        url: 'member/grade/store/list',
        method: 'get',
        params: params
    })
}
