<template>
    <div class="cardSellView">
        <div v-if="viewShow != 2">
            <!--选择卡种-->
            <transition name="chooseCard">
                <div class="chooseCard-container content-index">
                    <div class="chooseCard">
                        <div style="padding:20px 0 0 20px">
                            <el-page-header
                                    style="padding:10px"
                                    @back="$router.go(-1)"
                                    content="充值(选择卡种)"
                            ></el-page-header>
                        </div>
                        <div
                                v-if="gradeList && gradeList.length != 0"
                                class="cardTypeList listView"
                                style="height: calc(100vh - 170px);"
                                v-loading="cardLoading"
                                element-loading-text="加载充值档次中"
                        >
                            <div
                                    class="gradeItem"
                                    :class="{ gradeItemActive: gradeActive === index }"
                                    v-for="(item, index) in gradeList"
                                    :key="index"
                                    @click="choiceGrade(index, item)"
                            >
                                {{ item.gradeName }}
                            </div>
                        </div>
                        <div v-else class="hint-box">
                            <div class="hint-main">
                                <img src="../../assets/img/ze-vip-card-o.png" alt=""/>
                                <dir class="hint-text">
                                    您还没有配置充值卡种，请前往
                                    <el-button
                                            type="text"
                                            @click="cardConfiguration"
                                    >卡配置
                                    </el-button
                                    >
                                    配置后再使用
                                </dir>
                            </div>
                        </div>
                    </div>

                    <!-- 表单部分 -->
                    <div class="from-container">
                        <h3>请填写用户信息</h3>
                        <div>
                            <el-form
                                    :model="rechargeForm"
                                    :rules="rules"
                                    ref="ruleForm"
                                    label-width="150px"
                                    class="demo-ruleForm"
                            >
                                <el-form-item label="请输入手机号" prop="phone1">
                                    <el-input
                                            v-model="rechargeForm.phone1"
                                            type="text"
                                            @paste.native.capture.prevent="handlePaste"
                                            @copy.native.capture.prevent="handlePaste"
                                            @cut.native.capture.prevent="handlePaste"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="请再次输入手机号" prop="phone">
                                    <el-input
                                            v-model="rechargeForm.phone"
                                            @paste.native.capture.prevent="handlePaste"
                                            @copy.native.capture.prevent="handlePaste"
                                            @cut.native.capture.prevent="handlePaste"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="请输入姓名" prop="realName">
                                    <el-input
                                            v-model.trim="rechargeForm.realName"
                                            :disabled="userId != ''"
                                            @paste.native.capture.prevent="handlePaste"
                                            @copy.native.capture.prevent="handlePaste"
                                            @cut.native.capture.prevent="handlePaste"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="请输入性别" prop="gender">
                                    <el-select
                                            v-model="rechargeForm.gender"
                                            placeholder="请选择性别"
                                            :disabled="userId != ''"
                                    >
                                        <el-option label="男" value="1"></el-option>
                                        <el-option label="女" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="生日">
                                    <el-date-picker
                                            v-model="rechargeForm.birthday"
                                            type="date"
                                            format="yyyy 年 MM 月 dd 日"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择生日"
                                            :disabled="userId != ''"
                                    ></el-date-picker>
                                </el-form-item>
                                <el-form-item label="请输入卡号">
                                    <el-input
                                            v-model="rechargeForm.cardNo"
                                            :disabled="userId != ''"
                                            @paste.native.capture.prevent="handlePaste"
                                            @copy.native.capture.prevent="handlePaste"
                                            @cut.native.capture.prevent="handlePaste"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                        label="请输入充值余额"
                                        v-if="cardActivityType == 3"
                                >
                                    <el-input
                                            v-model="payPrice"
                                            @paste.native.capture.prevent="handlePaste"
                                            @copy.native.capture.prevent="handlePaste"
                                            @cut.native.capture.prevent="handlePaste"
                                    ></el-input>
                                </el-form-item>
                            </el-form>
                            <div class="Recharge">
                                <el-button type="primary" @click="goToRecharge">
                                    前往结算
                                </el-button>
                            </div>
                        </div>
                    </div>
                    `
                </div>
            </transition>
            <!--支付页面-->
            <transition name="payFullscreen">
                <div
                        class="cardCardCheckoutView"
                        v-if="q"
                        v-loading="otherPayLoading"
                        element-loading-text="充值中..."
                >
                    <div class="leftView" style="margin-right: 480px">
                        <div class="topView">
                            <label>支付</label>
                            <!--              <button class="btn-back" @click="handleCloseCardPay"></button>-->
                            <div class="btn-back iconfont icon-31fanhui1" @click="handleCloseCardPay"></div>
                        </div>
                        <div class="contentView listView" style="height: 620px">
                            <div class="orderInfoView">
                                <div class="timeView">
                                    充值时间：{{ $moment().format("YYYY-MM-DD HH:mm") }}
                                </div>
                                <div class="cardView">
                                    <label>卡片名称：{{ this.rechargeForm.cardName }}</label>
                                </div>
                                <div class="toPayView">
                                    <div class="amtItem subItem">
                                        <label class="label-name">
                                            <span>储值应收</span>
                                        </label>
                                        <label class="label-amt">
                                            <span>¥ {{ payPrice }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="paymentView">
                                <div class="tView">支付方式</div>
                                <div class="listView">
                                    <div
                                            class="paymentItem listItem"
                                            v-for="(item, index) in pay_ways"
                                            :key="index"
                                            @click="handlePayWay(item)"
                                    >
                                        <div class="iconView">
                                            <img :src="item.ico" v-if="item.label != 12"/>
                                            <span
                                                    v-else
                                                    class="iconfont icon-31saoma"
                                                    style="font-size:40px"
                                            ></span>
                                        </div>
                                        <div class="textView overflowText">{{ item.name }}</div>
                                        <div class="selectView" v-if="payWay === item.label">
                                            <img
                                                    src="../../assets/navImages/Def_Icon_Select_Black.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btnView">
                          <el-button
                            type="primary"
                            style="width: 260px"
                            @click="recharge"
                            v-if="payWay !== 6"
                          >
                            结账
                          </el-button>
                        </div>
                    </div>
                    <div class="rightView" style="width: 480px">
                        <div class="defImgView">
                            <img
                                    src="../../assets/navImages/Def_Bc_OrderCheckout_Right.png"
                            />
                            <p>{{ orderCheckoutVal }}</p>
                        </div>
                        <transition name="paidFullscreen">
                            <div
                                    id="qrCodePayPopView"
                                    class="popView"
                                    style="width: 480px"
                                    v-loading="qrCodePayView"
                                    :element-loading-text="qrCodePayViewName"
                                    v-if="payWayView"
                            >
                                <div class="popView-bcView" @click="handleClosePayWay"></div>
                                <div
                                        class="popView-contentView"
                                        style="width: 450px; top: 0; right: 0; overflow: auto"
                                >
                                    <div class="qrCodePayView" style="height: 794px">
                                        <div class="contentView" style="height: 513px">
                                            <div class="amtView">
                                                ¥
                                                <span>{{ payPrice }}</span>
                                            </div>
                                            <!-- <div class="imgView">
                                            <img src="https://www.shouqianba.com/img/logo.svg" />
                                          </div> -->
                                            <div class="qrCodeInputView">
                                                <el-input
                                                        class="input-scanPayCode"
                                                        placeholder="请扫描或输入付款码"
                                                        autocomplete="off"
                                                        ref="pay"
                                                        v-model="payVal"
                                                        type="number"
                                                        autofocus="autofocus"
                                                ></el-input>
                                                <el-button type="primary" @click="recharge">
                                                    收款
                                                </el-button>
                                            </div>
                                            <div class="tipView">
                                                请使用支付宝/微信扫码支付
                                                <p>支付过程中，请不要关闭该二维码！</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                            class="btn-close"
                                            @click="payWayView = false"
                                    ></button>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="rightView" style="width: 480px">
                        <div class="defImgView">
                            <img
                                    src="../../assets/navImages/Def_Bc_OrderCheckout_Right.png"
                            />
                            <p>{{ orderCheckoutVal }}</p>
                        </div>
                        <transition name="paidFullscreen">
                            <div
                                    id="qrCodePayPopView"
                                    class="popView"
                                    style="width: 480px"
                                    v-loading="qrCodePayView"
                                    :element-loading-text="qrCodePayViewName"
                                    v-if="payWayView1"
                            >
                                <div class="popView-bcView" @click="handleClosePayWay1"></div>
                                <div
                                        class="popView-contentView"
                                        style="width: 450px; top: 0; right: 0; overflow: auto"
                                >
                                    <div class="qrCodePayView" style="height: 794px">
                                        <div class="contentView" style="height: 513px">
                                            <div class="amtView">
                                                ¥
                                                <span>{{ payPrice }}</span>
                                            </div>
                                            <!-- <div class="imgView">
                                            <img src="https://www.shouqianba.com/img/logo.svg" />
                                          </div> -->
                                            <div class="qrCodeInputView">
                                                <el-input
                                                        class="input-scanPayCode"
                                                        placeholder="请扫描或输入付款码"
                                                        autocomplete="off"
                                                        ref="pay"
                                                        v-model="payVal"
                                                        type="number"
                                                        autofocus="autofocus"
                                                ></el-input>
                                                <el-button type="primary" @click="recharge">
                                                    收款
                                                </el-button>
                                            </div>
                                            <div class="tipView">
                                                请使用支付宝/微信扫码支付
                                                <p>支付过程中，请不要关闭该二维码！</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                            class="btn-close"
                                            @click="payWayView1 = false"
                                    ></button>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </transition>
        </div>
        <div v-else class="content-index">
            <div class="permission-box">
                <img class="img" src="../../assets/img/crying-face.png" alt=""/>
                <div class="text">抱歉, 尚未开通充值权限暂时无法进行充值!</div>
            </div>
        </div>

        <!-- 档次充值详情页面 -->
        <el-dialog
                title="选择充值卡种"
                :visible.sync="gradeDialogVisible"
                center
                class="cardGradeDialog"
                width="76%"
                :loading="dialogGradeLoading"
        >
            <el-tabs
                    v-if="gradeName != '随心充'"
                    v-model="activeValue"
                    @tab-click="getQueryCardList"
                    style="margin:0 30px"
            >
                <el-tab-pane
                        v-for="(item, index) in tabsList"
                        :key="index"
                        :label="item.name"
                        :name="item.value"
                >
                    <div
                            class="cardGradeList"
                            v-if="cardList.length"
                            v-loading="cardItemLoading"
                    >
                        <div
                                class="card-item"
                                v-for="(card, index) in cardList"
                                :key="index"
                                @click="choice(index, card)"
                                :style="
                isActive == index
                  ? 'border: 3px solid #3370ff'
                  : 'border: 3px solid #fff'
              "
                        >
                            <div class="card-box">
                                <div class="card-name">{{ card.cardName }}</div>
                                <div
                                        class="card-text"
                                        v-for="(benefits, benefitsIndex) in card.memberBenefitsList"
                                        :key="benefitsIndex"
                                >
                                    {{ benefitsIndex + 1 }}. {{ benefits.benefitsName }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                            v-else
                            style="text-align:center;padding-top:100px"
                            v-loading="dialogGradeLoading"
                    >
                        暂无数据
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="cardGradeList" v-else>
                <div
                        class="card-item"
                        v-for="(card, index) in cardList"
                        :key="index"
                        @click="choice(index, card)"
                        :style="
            isActive == index
              ? 'border: 3px solid #3370ff'
              : 'border: 3px solid #fff'
          "
                >
                    <div class="card-box">
                        <div class="card-name">{{ card.cardName }}</div>
                        <div
                                class="card-text"
                                v-for="(benefits, benefitsIndex) in card.memberBenefitsList"
                                :key="benefitsIndex"
                        >
                            {{ benefitsIndex + 1 }}. {{ benefits.benefitsName }}
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCard">确定卡种</el-button>
        <el-button @click="handleCancel">取消选择</el-button>
      </span>
        </el-dialog>
        <!-- 前往结算的弹出层 -->
        <el-dialog
                title="确认充值信息"
                :visible.sync="dialogVisible"
                center
                :destroy-on-close="true"
                custom-class="cardDialog"
                class="enter-info"
        >
            <div style="color: #303133">
                <div class="apply_card_info">
                    <div>
                        <div class="bkxx">
                            <div style="padding:2px 0">
                                <span class="userInfo_1_1">姓 名：</span>
                                <span>{{ rechargeForm.realName }}</span>
                            </div>
                            <div style="padding:2px 0">
                                <span class="userInfo_1_1">手机号：</span>
                                <span style="color: #860404;">{{ rechargeForm.phone }}</span>
                            </div>
                            <div style="padding:2px 0">
                                <span class="bkmc">充值卡种：</span>
                                <span>{{ rechargeForm.cardName }}</span>
                            </div>
                            <div style="padding:2px 0">
                                <span class="bkje">充值金额：</span>
                                <span style="color: #860404;"
                                >{{ rechargeForm.payPrice }}<a>元</a></span
                                >
                            </div>
                            <div class="quanyixq" style="display:flex">
                                <span>充值活动：</span>
                                <div>
                  <span
                          style="padding:2px 0"
                          v-for="(item, index) in rechargeForm.memberBenefitsList"
                          :key="index"
                  >
                    <p class="benefits">
                      {{ index + 1 }}、{{ item.benefitsName }}
                    </p>
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 10px;">
          <span style="margin-right: 10px; color: red; vertical-align: middle;">
            *
          </span>
                    <span class="xxsm">请仔细阅读确认信息以免充值错误,造成损失</span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goToSettlement">确定充值</el-button>
        <el-button @click="dialogVisible = false">取消充值</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
// import FullScreenDialog from "@/components/FullScreenDialog";
// import DateSelect from "@/components/DateSelect";
import {
    recharge,
    payRecharge,
    payRechargeQuery,
    getUserInfoForApplyCard,
} from "@/api/member/user";
import {getCardList, getQueryCardList} from "@/api/member/card";
import {getGradeList} from "@/api/member/grade";
import {isMobile} from "@/utils/validate";
import xianjin from "@/assets/navImages/Payment_cash.png";
// import saoma from "@/assets/navImages/saoma.jpg";
export default {
    name: "index",
    components: {
        // FullScreenDialog,
        // DateSelect,
    },
    data() {
        let validatePass1 = (rule, value, callback) => {
            if (!isMobile(value)) {
                callback(new Error("手机号格式不对"));
            } else {
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (!isMobile(value)) {
                callback(new Error("手机号格式不对"));
            } else if (value !== this.rechargeForm.phone1) {
                callback(new Error("两次输入手机号不一致!"));
            } else {
                this.getUser(value);
                callback();
            }
        };
        return {
            tabsList: [
                {
                    name: "无优惠",
                    value: "1",
                },
                {
                    name: "送权益卡(不退)",
                    value: "2",
                },
                {
                    name: "送权益卡(可退)",
                    value: "3",
                },
                {
                    name: "送优惠券",
                    value: "4",
                },
                {
                    name: "送日化用品",
                    value: "5",
                },
            ],
            activeName: "smyq",
            activeValue: "1",
            input: "",
            num4: 0, //计数器数量
            applyCardView: false,
            dialogVisible: false,
            q: false,
            isActive: 0,
            cardList: [],
            rules: {
                //验证规则
                phone1: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {
                        required: true,
                        validator: validatePass1,
                        trigger: ["blur", "change"],
                    },
                ],
                phone: [
                    {required: true, message: "请再次输入手机号", trigger: "blur"},
                    {
                        validator: validatePass2,
                        trigger: ["blur", "change"],
                    },
                ],
                realName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
            },
            rechargeForm: {
                userType: "",
                phone1: "",
                phone: "",
                cardNo: "",
                realName: "",
                gender: "",
                payPrice: 0,
                payCode: "",
                cardId: 0,
                cardName: "",
                channel: 1,
                payWay: 12,
                payWayName: "扫码支付",
                source: 1,
                birthday: "",
            },
            payPrice: "0",
            cardLoading: false,
            cardType: 2,
            cardId: 0,
            qrCodePayView: false,
            qrCodePayViewName: "收款中...",
            payWay: "",
            payWayView: false,
            payWayView1: false,
            // 支付方式
            pay_ways: [
                // {
                //   name: "现金",
                //   ico: xianjin,
                //   label: 4,
                // },
                // {
                //   name: "收钱吧",
                //   ico: "https://www.shouqianba.com/img/logo.svg",
                //   label: 6,
                // },
                {
                    name: "扫码支付",
                    label: 12,
                },
            ],
            chooseView: false,
            phone: "",
            expireDate: "",
            userInfo: {},
            // 支付二维码
            payVal: "",
            myInterval: null,
            shouInterval: null,
            userRecordId: "",
            // 充值所需
            updatePrice: 0,
            applyCard: 1,
            givePrice: "0",
            orderCheckoutVal: "请选择结账方式",
            otherPayLoading: false,
            result: {},
            cardActivityType: 0,
            outTradeNo: "",
            cardInfo: {},
            userId: "",
            gradeList: [],
            gradeActive: "",
            gradeDialogVisible: false,
            dialogGradeLoading: false,
            viewShow: 2,
            gradeName: "",
            cardItemLoading: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        // 判断传参手机号
        if (this.$route.query.phone) {
            this.phone = this.$route.query.phone;
            this.rechargeForm.phone1 = this.$route.query.phone;
            this.rechargeForm.phone = this.$route.query.phone;
            this.getUser(this.rechargeForm.phone);
        }
        // this.getCardList()
        this.getGradeList();
    },
    methods: {
        cardConfiguration() {
            this.$router.push("more/cardSet");
        },
        // 结账
        checkout() {
            console.log(this.payWay);
            if (!this.payWay) {
                this.$message.error("请选择支付方式");
                return false;
            }
        },
        handlePaste() {
        }, //禁止粘贴
        //前往结算
        goToRecharge() {
            // 判断是否选择卡种
            if (this.rechargeForm.cardId === 0) {
                this.$message.warning("请选择充值卡种");
                return false;
            }
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    // 判断随心充
                    if (this.cardActivityType == 3) {
                        if (this.payPrice <= 0) {
                            this.$message.warning("请输入正确的充值金额");
                            return false;
                        } else if (this.payPrice > 200) {
                            this.$message.warning("随心充金额不能大于200");
                            return false;
                        } else {
                            this.rechargeForm.payPrice = this.payPrice;
                        }
                    } else {
                        this.cardInfo = this.cardList[this.isActive];
                    }
                    this.dialogVisible = true;
                } else {
                    return false;
                }
            });
        },
        //前往结算
        goToSettlement() {
            this.q = true;
            this.dialogVisible = false;
        },
        //初始化权益卡列表 TODO
        getCardList: function () {
            this.cardLoading = true;
            const params = new URLSearchParams();
            params.append("cardType", this.cardType);
            params.append("cardId", this.cardId);
            getCardList(params).then((response) => {
                this.cardList = response.data.data;
                console.log(this.cardList);
                let card = this.cardList[this.isActive];
                this.cardActivityType = card.cardActivityType;
                this.rechargeForm.cardId = card.cardId;
                this.rechargeForm.cardName = card.cardName;
                this.rechargeForm.memberBenefitsList = card.memberBenefitsList;
                this.updatePrice = card.updatePrice;
                this.payPrice = card.cardPrice;
                this.cardLoading = false;
            });
        },
        cleanPhone() {
            this.phone = "";
        },
        // 选择卡
        choice(index, card) {
            this.isActive = index;
            this.payPrice = card.cardPrice;
            this.rechargeForm.cardId = card.cardId;
            this.rechargeForm.cardName = card.cardName;
            this.rechargeForm.memberBenefitsList = card.memberBenefitsList;
            this.updatePrice = card.updatePrice;
            this.cardActivityType = card.cardActivityType;
        },
        //输入手机号后查询用户信息
        getUser(phone) {
            const that = this;
            const params = new URLSearchParams();
            params.append("phone", phone);
            getUserInfoForApplyCard(params).then((response) => {
                console.log(response);
                // 说明是存在的用户
                if (response.data.data.userId !== null) {
                    that.userId = response.data.data.userId;
                    that.rechargeForm.realName = response.data.data.realName;
                    that.rechargeForm.gender = response.data.data.gender;
                    that.rechargeForm.birthday = response.data.data.birthday;
                    that.rechargeForm.cardNo = response.data.data.cardNo;
                    that.userInfo = response.data.data;
                    that.userInfo = response.data.data;
                } else {
                    that.userId = "";
                    that.rechargeForm.cardNo = that.rechargeForm.phone;
                }
            });
        },
        // 关闭会员卡支付页面
        handleCloseCardPay() {
            this.q = false;
            this.payWay = "";
            this.payWayView = false;
        },
        // 处理点击支付
        handlePayWay(item) {
            this.payWayView = false;
            this.payWay = item.label;
            this.rechargeForm.payWay = this.payWay;
            this.rechargeForm.payWayName = item.name;
            if (item.label === 6) {
                this.payWayView = true;
                this.$nextTick(() => {
                    this.$refs.pay.focus();
                });
            }
            if (item.label === 4) {
                this.orderCheckoutVal = "请使用" + item.name + "收款: " + this.payPrice;
            }
            if (item.label === 12) {
                this.payWayView1 = true;
                this.$nextTick(() => {
                    this.$refs.pay.focus();
                });
            }
        },
        // 获取档次列表
        async getGradeList() {
            let res = await getGradeList();
            this.gradeList = res.data.data;
            console.log(this.gradeList);
            this.viewShow = res.data.code;
        },
        // 选择档次
        async choiceGrade(index, item) {
            this.gradeActive = index;
            this.gradeId = item.gradeId;
            this.gradeName = item.gradeName;
            if (this.gradeName == "随心充") {
                this.activeValue = "0";
            } else {
                this.activeValue = "1";
            }
            await this.getQueryCardList();
            if (this.cardList && this.cardList.length != 0) {
                let card = this.cardList[0];
                this.payPrice = card.cardPrice;
                this.rechargeForm.cardId = card.cardId;
                this.rechargeForm.cardName = card.cardName;
                this.rechargeForm.memberBenefitsList = card.memberBenefitsList;
                this.updatePrice = card.updatePrice;
                this.cardActivityType = card.cardActivityType;
            }
            this.gradeDialogVisible = true;
        },
        async getQueryCardList() {
            this.dialogGradeLoading = true;
            this.cardItemLoading = true;
            let res = await getQueryCardList({
                gradeId: this.gradeId,
                cardActivityType: this.activeValue,
            });
            this.cardList = res.data.data;
            if (this.cardList && this.cardList.length != 0) {
                let card = this.cardList[0];
                this.payPrice = card.cardPrice;
                this.rechargeForm.cardId = card.cardId;
                this.rechargeForm.cardName = card.cardName;
                this.rechargeForm.memberBenefitsList = card.memberBenefitsList;
                this.updatePrice = card.updatePrice;
                this.cardActivityType = card.cardActivityType;
            }
            this.cardItemLoading = false;
            this.dialogGradeLoading = false;
        },
        // 确认卡种
        handleCard() {
            this.gradeDialogVisible = false;
            let card = this.cardList[this.isActive];
            if (card) {
                this.$message.success("确认卡种: 【" + card.cardName + "】");
                this.cardActivityType = card.cardActivityType;
                this.rechargeForm.cardId = card.cardId;
                this.rechargeForm.cardName = card.cardName;
                this.rechargeForm.memberBenefitsList = card.memberBenefitsList;
                this.updatePrice = card.updatePrice;
                this.rechargeForm.payPrice = card.cardPrice;
            } else {
                this.$message.error("卡种确认失败,请添加卡种");
                this.handleCancel();
                return false;
            }
        },
        // 取消选择
        handleCancel() {
            this.gradeDialogVisible = false;
            this.cardActivityType = 0;
            this.rechargeForm.cardId = 0;
            this.rechargeForm.cardName = "";
            this.updatePrice = 0;
            this.payPrice = "0";
        },
        // 关闭支付方式
        handleClosePayWay() {
            this.payWayView = false;
        },
        handleClosePayWay1() {
            this.payWayView1 = false;
        },
        recharge() {
            if (!this.payWay) {
                this.$message.error("请选择支付方式");
                return false;
            }
            if ((this.payWay == 6 || this.payWay == 12) && !this.payVal) {
                this.$message.error("请输入付款码");
                return false;
            }
            // if (this.payWay !== 5 && !this.payVal) {
            //     this.$message.error("请输入付款码");
            //     return false;
            // }
            if (this.payWay == 4) {
                this.otherPayLoading = true;
            } else {
                this.qrCodePayView = true;
            }
            if (this.payWay == 12) {
                this.qrCodePayView = true;
            }

            this.rechargeForm.payCode = this.payVal;
            recharge(this.rechargeForm).then((response) => {
                console.log(response);
                if (response.data.code == 0) {
                    this.result = response.data.data;
                    if (response.data.data) {
                        this.outTradeNo = response.data.data.outTradeNo;
                        this.userRecordId = response.data.data.userRecordId;
                        // 调用支付接口
                        this.payRecharge();
                    }
                } else {
                    this.qrCodePayView = false;
                    this.otherPayLoading = false;
                    //支付失败
                    this.$message.error(response.data.msg);
                }
            });
        },
        payRecharge() {
            let payWay = this.rechargeForm.payWay;
            let userRecordId = this.userRecordId;
            let authCode = this.rechargeForm.payCode;
            let data = {
                payWay,
                userRecordId,
                authCode,
            };
            payRecharge(data)
                .then((response) => {
                    // this.isPrint = false;
                    if (response.data.code === 0) {
                        // 只有微信 支付宝有回调
                        if (payWay === 1 || payWay === 2 || payWay === 6 || payWay === 12) {
                            this.outTradeNo = response.data.data.outOrderNo;
                            this.queryPayResult(this.outTradeNo); //调用接口的方法outOrderNo outOrderNo
                            this.myInterval = setInterval(() => {
                                this.queryPayResult(this.outTradeNo); //调用接口的方法
                            }, 3000);
                        } else {
                            this.commonPaySuccess();
                        }
                    } else {
                        if (payWay === 1 || payWay === 2 || payWay === 6 || payWay === 12) {
                            clearInterval(this.shouInterval);
                            // 需要用户支付
                            if (response.data.data) {
                                // 需要用户支付
                                if (response.data.data.subCode === "WAITING_PAYMENT") {
                                    this.outTradeNo = response.data.data.outOrderNo;
                                    this.queryPayResult(this.outTradeNo); //调用接口的方法
                                    this.myInterval = setInterval(() => {
                                        this.queryPayResult(this.outTradeNo); //调用接口的方法
                                    }, 3000);
                                } else {
                                    this.$message.error(response.data.data.subMsg);
                                    this.commonPayFailed();
                                }
                            } else {
                                if (response.data.data) {
                                    this.$message.error(response.data.data.subMsg);
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                                this.commonPayFailed();
                            }
                        } else {
                            this.commonPayFailed();
                            this.$message.error(response.data.data.subMsg);
                        }
                    }
                })
                .catch((reason) => {
                    // 要进行跳转到当前未支付的订单
                    console.log(reason);
                    this.qrCodePayView = false;
                });
            if (this.rechargeForm.payWay === 6 || this.rechargeForm.payWay === 12) {
                this.qrCodePayViewName = "等待用户输入密码...";
            }
        },
        // 查询支付结果
        queryPayResult(outTradeNo) {
            // const params = new URLSearchParams();
            // params.append("payWay", this.rechargeForm.payWay);
            // params.append("outTradeNo", this.outTradeNo);
            payRechargeQuery({
                payWay: this.rechargeForm.payWay,
                outTradeNo: outTradeNo,
            })
                .then((response) => {
                    // 微信
                    if (response.data.code == 0) {
                        if (response.data.data.subCode === "SUCCESS") {
                            clearInterval(this.shouInterval);
                            this.commonPaySuccess();
                        } else if (response.data.data.subCode === "WAITING_PAYMENT") {
                            this.qrCodePayViewName = "等待用户输入密码";
                        } else {
                            this.$message.error(response.data.data.subMsg);
                            this.rechargeForm.payCode = "";
                            this.commonPayFailed();
                        }
                    } else {
                        this.$message.error(response.data.msg);
                        this.commonPayFailed();
                    }
                })
                .catch(() => {
                    this.commonPayFailed();
                });
        },
        // 公共的
        commonPaySuccess() {
            this.qrCodePayView = false;
            this.$message({
                message: "充值成功!",
                type: "success",
            });
            clearInterval(this.myInterval);
            this.$router.push({
                name: "applyCardSuccess",
                query: {
                    userRecordId: this.userRecordId,
                },
            });
        },
        closePayModal() {
            this.qrCodePayView = false;
            clearInterval(this.myInterval);
        },
        commonPayFailed() {
            clearInterval(this.myInterval);
            this.$router.push({
                path: "/home/more/applyCardList",
            });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

// 超出显示...
.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 去掉返回按钮
// /deep/ .el-page-header__left .el-icon-back {
//   font-size: 0px;
//   margin-right: 0;
// }

// /deep/ .el-page-header__left {
//   margin-right: 0px;
// }

// /deep/ .el-page-header__left::after {
//   content: none;
// }

.cardSellView {
  user-select: text;

  .permission-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .text {
      color: rgba(16, 16, 16, 100);
      font-size: 16px;
    }
  }
}

// 选择卡片
.active {
  border: 3px solid #3370ff;
}

/deep/ .cardDialog {
  border-radius: 5px !important;
}

.userInfo {
  padding-left: 50%;

  div:nth-child(1) {
    font-size: 16px;
    font-weight: 700;
  }

  div:not(:first-child) {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }
}

.cardInfo {
  margin-left: 250px;
}

// .cardInfo {
//   padding-left: 25%;

//   div:nth-child(1) {
//     font-size: 16px;
//     font-weight: 700;
//   }

//   div:not(:first-child) {
//     font-size: 16px;
//     font-weight: 500;
//     margin-top: 5px;
//   }
// }

.chooseCard-container {
  display: flex;
  overflow: auto;

  .from-container {
    flex: 5;
    border-left: 1px solid #eff2f6;
    padding-left: 20px;
    height: calc(100vh - 80px);

    > div {
      width: 50%;
      margin-top: 25px;

      .el-select {
        width: 100%;
      }

      .el-date-editor {
        width: 100%;
      }

      .el-form-item {
        margin-bottom: 25px;
      }

      .Recharge {
        display: flex;
        justify-content: center;

        .el-button {
          width: 40%;
        }
      }
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cardBuyView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  font-family: PingFangSC-Regular;

  > .topView {
    background: #fff;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.01);
  }

  > .sellInfoView .inputView {
    display: flex;
    height: 44px;
    padding: 8px 10px;
    line-height: 28px;
    background: #f8f8f8;
    border-radius: 6px;
    font-size: 15px;
    margin-bottom: 12px;
    /* margin-bottom: 0; */
    > input {
      flex: 1;
      background: #f8f8f8;
      text-align: right;
      font-size: 15px;
      color: #333;
      line-height: 28px;
      border: none;
      outline: none;
      padding: 0;
    }

    > input.input-payAmt[disabled] {
      color: #ff5e56;
      opacity: 1;
    }
  }

  > .sellInfoView {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    > .cusView {
      width: 380px;
      min-width: 380px;
      padding: 0 55px 35px 55px;
      border-right: 0.5px solid rgba(220, 220, 220, 0.7);
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
      }

      > .cardView {
        position: relative;
        width: 270px;
        height: 170px;
        margin-bottom: 15px;

        > img {
          width: 270px;
          height: 170px;
          border-radius: 6px;
        }

        > .cardNameView {
          position: absolute;
          left: 15px;
          top: 15px;
          line-height: 25px;
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #fff;
        }
      }

      > .infoView {
        border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);

        > div {
          display: flex;
          margin-bottom: 12px;
          height: 44px;
          padding: 8px 10px;
          line-height: 28px;
          background: #f8f8f8;
          border-radius: 6px;
          font-size: 15px;

          label {
            width: 90px;
            color: #666;
          }
        }

        > div.sexView {
          background: #fff;
          margin: -7px 0 5px 0;

          > div {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
          }
        }

        > div.dateView {
          background: #fff;

          > div {
            width: 160px;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: flex;
          }
        }
      }
    }

    > .accView {
      flex: 1;
      padding: 0 0 15px 0;
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 0 55px;
      }

      > .buyInfoView {
        padding: 0 40px;
        padding-bottom: 35px;

        > .amtView {
          padding-right: 20px;
        }

        > .btnView {
          text-align: center;
          margin-top: 12px;

          > button {
            width: 340px;
            height: 44px;
            background: #3370ff;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }

  > .topupInfoView .inputView {
    display: flex;
    height: 44px;
    padding: 8px 10px;
    line-height: 28px;
    background: #f8f8f8;
    border-radius: 6px;
    font-size: 15px;
    margin-bottom: 12px;

    > label {
      width: 90px;
      color: #666;
    }

    > input {
      flex: 1;
      background: #f8f8f8;
      text-align: right;
      font-size: 15px;
      color: #333;
      line-height: 28px;
      border: none;
      outline: none;
      padding: 0;
    }

    > input.input-payAmt[disabled] {
      color: #ff5e56;
      opacity: 1;
    }
  }

  > .topupInfoView {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 25px;

    > .cusView {
      width: 380px;
      padding: 0 0 15px 0;
      border-right: 0.5px solid rgba(220, 220, 220, 0.7);
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 40%;

      > .tView {
        padding: 0 30px;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
      }

      > .cardView {
        position: relative;
        width: 270px;
        height: 170px;
        margin-bottom: 15px;

        > img {
          width: 270px;
          height: 170px;
          border-radius: 6px;
        }

        > .cardNameView {
          position: absolute;
          left: 15px;
          top: 15px;
          line-height: 25px;
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #fff;
        }
      }

      > .cardInfoView {
        padding: 25px 30px;
        line-height: 30px;

        > .baseInfoView {
          padding: 0 40px 25px 40px;

          > div {
            display: flex;
            font-size: 15px;

            > label:first-child {
              color: #999;
            }

            > label:last-child {
              flex: 1;
              color: #333;
              text-align: right;
            }
          }
        }

        > .accInfoView {
          border-top: 0.5px dashed rgba(220, 220, 220, 0.7);

          > .tView {
            padding: 15px 0 10px;
            line-height: 25px;
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            color: #333;

            > span {
              font-family: PingFangSC-Regular;
              font-size: 13px;
              color: #999;
              margin-left: 12px;
            }
          }

          > .listView {
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 25px;

            > .accItem {
              display: flex;
              line-height: 35px;

              > label:first-child {
                color: #999;
              }

              > label:last-child {
                flex: 1;
                color: #333;
                text-align: right;
              }
            }
          }
        }
      }
    }

    > .topupInfoView {
      flex: 1;
      padding: 0 55px 15px 55px;
      overflow-x: hidden;
      overflow-y: auto;

      > .tView {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 0 55px;
      }

      > .infoView {
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 25px;

        > .amtView {
          padding-right: 20px;
        }

        > .btnView {
          text-align: center;
          margin-top: 12px;

          > button {
            width: 340px;
            height: 44px;
            background: #3370ff;
            border-radius: 6px;
            line-height: 44px;
            padding: 0;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }
  }
}

//动画过程
.cardDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.cardDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.cardDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.cardDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  left: 70px;
  width: 100%;
  height: 100%;
  z-index: 100;

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      > label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        background-size: 28px;
        border: none;
        outline: none;
        font-size: 20px;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 2;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 87px;
            height: 87px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png) left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

p {
  margin: 0;
  padding: 0;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          margin-right: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png) center / 28px 28px no-repeat;
      border: none;
      outline: none;
    }
  }
}

//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: paidFullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes paidFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes paidFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.chooseCard {
  flex: 5;

  .hint-box {
    height: calc(100vh - 170px);
    position: relative;

    .hint-main {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(50vw - 100px);

      .hint-img {
      }

      .hint-text {
        color: #b2b2b2;
        font-size: 14px;
        padding: 0;
        margin: 0;
        width: 100%;

        .el-button {
          font-size: 14px;
        }
      }
    }
  }

  > .topView {
    padding: 15px 25px 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.01);
    font-size: 24px;
    font-weight: 700;

    .text {
      padding-top: 15px;
      font-size: 16px;
      font-weight: 400;
      padding-left: 10px;
    }
  }

  > .cardTypeList {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;
    //margin-left: 15px;
    // margin-top: 15px;
    // padding: 15px 0;

    > .cardTypeItem {
      float: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      width: 290px;
      height: 200px;

      > .imgView {
        position: relative;
        // padding: 25px 20px 0 20px;
        > img {
          width: 270px;
          border-radius: 6px;
        }

        > .cardNameView {
          position: absolute;
          left: 26px;
          top: 25px;
          overflow: hidden;
          font-family: PingFangSC-Semibold;
          font-size: 18px;
          color: #fff;
        }

        > .topupAmtView {
          position: absolute;
          left: 26px;
          top: 60px;
          width: auto;
          font-family: PingFangSC-Semibold;
          font-size: 14px;
          color: #fff;
        }
      }

      > .bView {
        display: flex;
        padding: 10px 20px;

        > .textView {
          flex: 1;
        }

        > .btnView {
          margin-left: 10px;
          width: 80px;

          > button {
            width: 80px;
            height: 40px;
            border-radius: 6px;
            font-family: PingFangSC-Semibold;
            font-size: 16px;
            color: #3370ff;
            padding: 0;
            border: 2px solid #3370ff;
          }
        }
      }
    }
  }
}

//动画过程
.chooseCard-enter {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

.chooseCard-enter-active {
  animation: chooseCard-dialog-fade-in 0.9s ease;
}

.chooseCard-leave-active {
  animation: chooseCard-dialog-fade-out 0.9s ease forwards;
}

@keyframes chooseCard-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes chooseCard-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.gradeItem {
  float: left;
  border-radius: 4px;
  background: #f8f8f8;
  width: 180px;
  height: 80px;
  margin-bottom: 25px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.gradeItemActive {
  color: #3370ff;
  box-shadow: 0 2px 7px 0 rgba(85, 110, 97, 0.35);
  border-radius: 7px;
  border: 2px solid #3370ff;
}

.gradeItemActive:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border: 14px solid #3370ff;
  border-top-color: transparent;
  border-left-color: transparent;
}

.gradeItemActive:after {
  content: "";
  width: 3px;
  height: 10px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.cardGradeList {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;

  .card-item {
    border-radius: 5px;
    display: flex;
    width: 296px;
    margin: 0 10px 10px 0;
    box-sizing: border-box;
  }

  .card-box {
    width: 280px;
    height: 176px;
    background-image: url(../../assets/navImages/topup.png);
    background-size: 280px 176px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
    margin: 5px;
    padding: 10px 10px 0;
    color: #fff;

    .card-name {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .card-text {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }
}

.cardGradeDialog {
  /deep/ .el-dialog {
    .el-dialog__body {
      padding: 0;
      min-height: 300px;
      max-height: 500px;
      overflow: auto;
    }
  }
}

.enter-info {
  .apply_card_info {
    display: grid;
    justify-content: center;
    margin-top: 0;
    font-size: 15px;

    .userInfo_1_1 {
      width: 74px;
      display: inline-block;
      text-align: right;
    }
  }

  .xxsm {
    font-weight: bold;
    font-size: 16px;
  }

  // ::v-deep .el-dialog__body {
  //   position: relative;
  //   height: 250px;
  // }
  // .content-text {
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   .text1 {
  //     color: rgba(16, 16, 16, 100);
  //     font-size: 16px;
  //     display: flex;
  //     margin: 0 auto;
  //     line-height: 22px;
  //     span:nth-child(1) {
  //       width: 80px;
  //       text-align: right;
  //     }
  //   }
  //   .text2 {
  //     color: #860404;
  //   }
  //   .text-info {
  //     text-align: center;
  //     margin-top: 10px;
  //     position: absolute;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     bottom: -50px;
  //     width: 300px;
  //   }
  // }
}

.user1 {
  margin-left: 220px;
  font-weight: bold;
  font-size: 15px;
}

.iphone1 {
  color: red;
}

.yuedu {
  font-weight: bold;
}

.cardInfo {
  font-size: 15px;
  margin-top: 20px;
  font-weight: bold;
}

.czxx {
  margin-left: -30px;
}

.kzxq {
  margin-left: -5px;
}

.kzmc {
  color: red;
  margin-right: 10px;
}

.kzmc1 {
  margin-right: 10px;
}

.czxm {
  margin-right: 10px;
}

.sjhao {
  margin-right: 10px;
}

.topupAmtview1 {
  color: red;
  margin-left: 80px;
}

.row-bg {
  padding: 100px;
}

.quanxiangq {
  border: 3px solid #3370ff;
  width: 320px;
  height: 130px;
  margin-top: 20px;
}

.add-dialog /deep/ .el-icon-close:before {
  color: white;
}

.add-dialog /deep/ .el-dialog__header {
  background-color: #3370ff;
}

.add-dialog /deep/ .el-dialog__title {
  color: white;
}

.el1 {
  margin-top: 70px;
}

.yanquanshuliang {
  margin-top: 30px;
}

.yanquanshuliang2 {
  margin-top: 30px;
}

.yesxiaofei {
  margin-left: 100px;
}

.qxxf {
  margin-left: 200px;
}

.quanxiangq:before {
  content: "";
  position: absolute;
  left: 300px;
  bottom: 100px;
  border: 14px solid #3370ff;
  // border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  // border-right-color:transparent;
}

.quanxiangq:after {
  content: "";
  width: 3px;
  height: 10px;
  position: absolute;
  left: 315px;
  bottom: 115px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
</style>
